import React from 'react';

function HeroAbout() {
  return (
    <section className="relative">

      {/* Background image */}
      <div className="absolute inset-0">
        {/* <img className="w-full h-full object-cover" src={require('../images/about-hero.jpg')} width="1440" height="394" alt="About" /> */}
        <div className="absolute inset-0 bg-gray-900 opacity-75" aria-hidden="true"></div>
      </div>

      {/* Hero content */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="h1 mb-4" data-aos="fade-up">Who are we?</h1>
            <p className="text-xl text-gray-400 mb-8" data-aos="fade-up" data-aos-delay="200">We’re a team of graduate students in the <a href="https://www.ischool.berkeley.edu/" target="_blank"><b>School of Information</b></a> at the University of California, Berkeley. The goal of our project is to make a meaningful contribution to the democratization of exercising data privacy rights in a free and open source way. We’re not backed by any big corporations or data brokers, in fact we’re not funded at all, because <i>we’re not even a company</i>. We just want to give power back to people that want to protect their personal information because this is something we really care about.</p>
          </div>
        </div>
      </div>

    </section>
  );
}

export default HeroAbout;