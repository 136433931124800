import React, { useState } from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import Footer from '../partials/Footer';

function FAQ() {

  const [page, setPage] = useState(1);

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div className="pt-32 pb-12 md:pt-40 md:pb-20">
            <div className="flex flex-col md:flex-row">

              {/* Main content */}
              <main className="md:flex-auto md:pl-10 order-1" data-aos="fade-up">

                {/* What are data brokers and people search sites? */}
                <div className={page !== 3 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">What are data brokers and people search sites?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">Data brokers are companies that collect and sell personal data in bulk, usually for marketing purposes. By buying or licensing data or scraping public records, data brokers can assemble thousands of attributes each for billions of people around the world. If you use a smartphone or a credit card, it’s not difficult for a data broker to know if you’ve just gone through a break-up, if you’re pregnant or trying to lose weight, what medicine you take, where you’ve been, and even how you swipe on your smartphone. You probably haven’t heard of any of them and they don’t offer any value for the average consumer, yet they make up a multi-billion dollar per year industry. </p>
                      &nbsp;
                      <p className="text-lg text-gray-400">People search sites scrape public records and sell it to other people or companies who want information on you specifically. People-search sites allow anyone on the internet to query your name and potentially retrieve your phone number, address, age, date of birth, and even the names of your relatives! If you were to look yourself up on one of these sites, you may find that they know a surprising amount of information about you. </p>
                      &nbsp;
                      <p className="text-lg text-gray-400">If you want to read more into data brokers, we suggest you start with this <a href="https://www.nytimes.com/interactive/2018/12/10/business/location-data-privacy-apps.html" target="_blank"><span class="text-purple-600">New York Times article.</span></a></p>
                    </li>
                  </ul>
                </div>

                {/* Why should I care about who has my data? */}
                <div className={page !== 2 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">Why should I care about who has my data?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">It can all start with blindly accepting “share my location” on your smartphone to a seemingly innocuous app. This small action can trigger the collection of a giant web of highly granular information and the buying/selling/processing of this data by and to a myriad of databroker services. And it’s often much more data than you may anticipate.</p>
                      &nbsp;
                      <p className="text-lg text-gray-400">There are lots of issues with this. To site one salient example, <a href="https://www.vice.com/en_us/article/jgxk3g/secret-service-phone-location-data-babel-street" target="_blank"><span class="text-purple-600">this article</span></a> from Vice demonstrates how Federal Law Enforcement agencies buy data from data brokers. This essentially violates your 4th amendment rights as these are treated as vendor purchases and not something that requires search warrants or due process.</p>
                      &nbsp;
                      <p className="text-lg text-gray-400">If that isn’t enough to convince you, <a href="https://medium.com/@FabioAEsteves/i-have-nothing-to-hide-why-should-i-care-about-my-privacy-f488281b8f1d" target="_blank"><span class="text-purple-600">this article</span></a> by Fábio Estevez does a superb job explaining why you should care about who has your data. In case you don’t want to read it, the main takeaway is that privacy isn’t the same as secrecy. Just because you have nothing to hide doesn’t mean you shouldn’t keep your data private, and it’s your right to do so.</p>
                    </li>
                  </ul>
                </div>


                {/* Which data brokers / people search sites / marketing list companies does PrivacyBot support? */}
                <div className={page !== 4 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">Which data brokers / people search sites / marketing list companies does PrivacyBot support?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">You can see our full list of services <a href="https://docs.google.com/spreadsheets/d/1R3wfIxEH0v_gmjBBXYP6FZcwA03dqPAXkN_52m59pOg/edit?usp=sharing" target="_blank"><span class="text-purple-600">here</span></a>, this is what we believe to be the most exhaustive list of data brokers and people search sites publicly available. This list was painstakingly compiled through multiple sources including the CA AG data broker registry as well as the Vermont AG’s data broker registry. If you think we are missing any or have out of date information, please contact us here.</p>
                    </li>
                  </ul>
                </div>


                {/* What is the California Consumer Privacy Act (CCPA)? */}
                <div className={page !== 5 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">What is the California Consumer Privacy Act (CCPA)?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">The CCPA gives Californians more ownership and control over their personal information so you have the power to request your data and delete it. It also allows you to request that your data not be sold to third parties. It allows the state to investigate and enforce these rights.</p>
                      &nbsp;
                      <p className="text-lg text-gray-400">Read more about the CCPA in <a href="https://blog.mozilla.org/firefox/new-year-new-rights/" target="_blank"><span class="text-purple-600">this article</span></a> by Mozilla and on the <a href="https://oag.ca.gov/privacy/ccpa" target="_blank"><span class="text-purple-600">CA Attorney General website</span></a>.</p>
                    </li>
                  </ul>
                </div>

                {/* Does PrivacyBot work if I am not a California resident?*/}
                <div className={page !== 6 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">Does PrivacyBot work if I am not a California resident?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">Technically CCPA only applies to those that reside in California, but because so many businesses do business in the state, they will likely make changes that impact people everywhere. Many U.S states are considering legislation of their own. Again, you can read more <a href="https://blog.mozilla.org/firefox/new-year-new-rights/" target="_blank"><span class="text-purple-600">here</span></a>. </p>
                    </li>
                  </ul>
                </div>

                {/* What PII do I need to provide? */}
                <div className={page !== 7 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">What PII do I need to provide?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">The only required data fields to run PrivacyBot are your name, email, and your state of residence. Any other information (the other data fields)  is optional and left to your discretion whether or not you want to send it. Please only send what information you are comfortable with sending and always err on the side of caution if you are unsure. But, the more information you include the less additional follow up you will have to do with data brokers in order for them to fulfill the request. Additionally, any info you provide can only be used by the company for verification purposes and will not be added to the company’s marketing practices (per CCPA rules but again companies may still potentially violate those rules).</p>
                      &nbsp;
                      <p className="text-lg text-gray-400">Different data brokers require different types of PII in order to verify requests, PrivacyBot’s data broker list compiles exactly what PII is needed for each company’s opt out and sends that information and only that information. For example, suppose you complete all input fields in PrivacyBot [first and last name, email, full address including city, zip, state, dob, phone number, last 4 digits of credit card, device advertising id, and twitter handle]. If a certain service only required [name, email, and phone number] that service would not receive additional information such as address or device advertising id despite the fact that you imputed that information into the PrivacyBot input fields.</p>
                    </li>
                  </ul>
                </div>

                {/* The email was sent to the data broker but they didn’t respond, what should I do? */}
                <div className={page !== 8 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">The email was sent to the data broker but they didn’t respond, what should I do?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">If you want to manually keep track of which services have responded to your request, <a href="https://docs.google.com/spreadsheets/d/1R3wfIxEH0v_gmjBBXYP6FZcwA03dqPAXkN_52m59pOg/edit?usp=sharing" target="_blank"><span class="text-purple-600">here</span></a> is a link to our service list. If a company has not responded within a period of two weeks, we recommend you visit their website located on the service list, navigate to their privacy policy (or a section that says something along the lines of CCPA, California Residents, Opt-out, etc.), and manually submit a request.  But keep in mind that legally a company has 45 days to respond before you can file a report against them. </p>
                    </li>
                  </ul>
                </div>

                {/* A company is intentionally non compliant with my request, what should I do?*/}
                <div className={page !== 9 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">A company is intentionally non compliant with my request, what should I do?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">If you submitted a valid CCPA request and the company has not complied with the request within the 45 day time period, we recommend reading the <a href="https://oag.ca.gov/privacy/ccpa" target="_blank"><span class="text-purple-600">California Attorney General's CCPA website</span></a> which has steps on how to file a consumer complaint against a business for violating CCPA.</p>
                    </li>
                  </ul>
                </div>

                {/* Can I trust PrivacyBot? */}
                <div className={page !== 10 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">Can I trust PrivacyBot?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">We don’t work for any corporations, we aren’t receiving funds from anyone. We’re just a few grad students who want to make privacy rights more accessible. We would encourage everyone to examine our codebase in our public repository and decide if you are comfortable proceeding. </p>
                    </li>
                  </ul>
                </div>

                {/* I ran into an error, how do I fix it? */}
                <div className={page !== 11 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">I ran into an error, how do I fix it?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">We recommend reading through our README file on the github repository and making sure you’ve done each step correctly. We will also be posting a tutorial video covering installation very soon and that will be linked here 
                      as well as on the github. Unfortunately we do not have the capacity to help troubleshoot every user but if you think you have found an error in the core code please make an issue request on github. We also want to be very clear that this project was built by 4 grad students trying our best and although we have done our best to ensure things will run smoothly, there is a chance of issues arising. Usually these will take the form of a python PATH or dependency issues. We hope to address these issues by wrapping the entire project into an installer but this will have to happen in future versions.</p>
                    </li>
                  </ul>
                </div>

                {/* One (or several) of the data broker privacy department contact emails bounced / your databroker service list is out of date. Can I fix it? */}
                <div className={page !== 12 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">One (or several) of the data broker privacy department contact emails bounced / your databroker service list is out of date. Can I fix it?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">If data broker information is incorrect on our services list, you can absolutely contribute to the updating and maintenance of the list. In fact, our main goal is to make it a community driver effort. As of now, we are using a google sheet as the main data source of our service list. This may change going forward, but the best way is to either make an issue request in github or send us an email. You can also make manual changes to the services csv from the project directory on your local machine.</p>
                    </li>
                  </ul>
                </div>

                {/* I am not a gmail user, can I still use PrivacyBot? */}
                <div className={page !== 13 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">I am not a gmail user, can I still use PrivacyBot?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">Currently the project only supports vanilla gmail accounts (meaning no SSO). The reason for this is mostly due to time and resource constraints of our team but also at a technical level, PrivacyBot used the gmail API to access user email via 0auth tokens (for security reasons) and to provide the automatic label functionality. If you would like, we can provide a previous version of the PrivacyBot core functions which uses Python’s SMTP module to send emails as opposed to the gmail api. Getting this to work for your specific email client will require some additional work and perhaps some light python knowledge. </p>
                    </li>
                  </ul>
                </div>

                {/* Why does PrivacyBot ask for the permissions to ‘send emails’ and ‘view and modify but not delete’ in my gmail? */}
                <div className={page !== 14 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">Why does PrivacyBot ask for the permissions to ‘send emails’ and ‘view and modify but not delete’ in my gmail?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">PrivacyBot’s core functionality uses the gmail API and those are the permissions needed in order to provide the functionality of sending the CCPA data delete requests as well as creating the label (“PrivacyBot”) in your inbox for better organization - this is what the 2nd permission is explicitly pertaining to. PrivacyBot does not store any information on users who run the application and does view / store / process any information from your gmail inbox. As described in the read me on our github, we strongly recommend PrivacyBot users to remove PrivacyBot’s permissions from their gmail account after they are done running the service. </p>
                    </li>
                  </ul>
                </div>
                

                {/* I am a [data broker / marketing list company / people search site] who received a request. What do I do? */}
                <div className={page !== 15 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">I am a [data broker / marketing list company / people search site] who received a request. What do I do?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">PrivacyBot allows users to send California Consumer Privacy Act (CCPA) data delete request emails from their own email accounts to a list of data brokers and people search sites. In other words, PrivacyBot is a locally run application which merely assists people sending data delete requests from their own email accounts and is not an ‘authorized agent’.</p>
                      &nbsp;
                      <p className="text-lg text-gray-400">The emails your company received were from the personal email accounts of individuals who are <i>consciously</i> and <i>deliberately</i> wishing to exercise their rights under CCPA and were not sent by the PrivacyBot team. PrivacyBot does not store, process, or track personal information of those running the application in any way. </p>
                      &nbsp;
                      <p className="text-lg text-gray-400">If your company processes CCPA requests via a different method (for example a web form) you can direct individuals to that method.</p>
                      &nbsp;
                      <p className="text-lg text-gray-400">For further information on CCPA, please refer to the <a href="https://oag.ca.gov/privacy/ccpa" target="_blank"><span class="text-purple-600">California Attorney General's CCPA website</span></a></p>
                    </li>
                  </ul>
                </div>
              
                {/* My company does not deal with consumer data and I think it should be removed from the PrivacyBot services list*/}
                <div className={page !== 16 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">My company does not deal with consumer data and I think it should be removed from the PrivacyBot services list</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">We have taken care to ensure that the email recipients are correct and should be privacy departments (or in some cases customer service departments) of companies and not unrelated or irrelevant recipients. Rarely, there are companies on our services list which were mistakenly categorized as data brokers (or marketing list companies or people search sites) and in those instances we will review and remove them if they deem to be services which we are out of our purview.If you think this case applies to you can send us an <a href="mailto:privacybot.berkeley@gmail.com?Subject=Databroker%20Removal" target="_top">email </a>but <b>DO NOT SEND ANY DATA SUBJECT PII IN THE EMAIL</b></p>
                    </li>
                  </ul>
                </div>

                {/* Is PrivacyBot an ‘authorized agent’ ?*/}
                <div className={page !== 17 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">Is PrivacyBot an ‘authorized agent’ ?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">PrivacyBot is a locally run application which merely assists people sending data delete requests from their own email accounts and therefore does not need to be an ‘authorized agent’. The requests are sent by individuals using their own computers and accounts who are consciously and deliberately wishing to exercise their rights under CCPA and were not sent by the PrivacyBot team. PrivacyBot is not acting as an ‘authorized agent’.</p>
                    </li>
                  </ul>
                </div>

                {/*  Can I contribute to this project?*/}
                <div className={page !== 18 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4"> Can I contribute to this project?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="text-lg text-gray-400">If you wish to contribute you can send us an <a href="mailto:cslopez1@gmail.com?Subject=PrivacyBot%20Contributor" target="_top">email</a>! We are actively looking for privacy enthusiasts to help us with various aspects of this project. </p>
                    </li>
                  </ul>
                </div>
                

                {/* How does PrivacyBot work? */}
                <div className={page !== 1 ? 'hidden' : undefined}>
                  <div className="mb-8">
                    <h2 className="h2 mb-4">How does PrivacyBot work?</h2>
                  </div>
                  <ul className="-my-4">
                    <li>
                      <p className="font-architects-daughter text-xl text-purple-600 mb-2">Here’s an elaboration on the three steps from our home page:</p>
                      &nbsp;
                      <h4 className="text-xl font-medium  mb-2">1) You fill in as many required data fields as you’re comfortable with</h4>
                      &nbsp;
                      <p className="text-lg text-gray-400">It’s ironic, we know -- that you have to provide more data in order to delete it. But data brokers need to collect a certain minimum amount of info to verify your identity and ensure they’re deleting the right person’s data. The amount of data required varies by data broker, but PrivacyBot only sends the minimum amount of information required for each data broker to delete your info, nothing more. The bare minimum you must enter in order to be considered for many data deletion requests is your name, email, and the state you reside in, so we do need you to enter in at least that much in order to use PrivacyBot. Any other information is optional and left to your discretion whether or not you want to send it. But, the more information you include, the more data brokers will accept your deletion requests.</p>
                      &nbsp;
                      <h4 className="text-xl font-medium  mb-2">2) Data deletion requests are sent from your email. </h4>
                      &nbsp;
                      <p className="text-lg text-gray-400">PrivacyBot is essentially a smart email routing tool. It allows you to send CCPA data delete requests en masse right from your own email. It does this by accessing your gmail through 0auth and the gmail api and is run entirely from your own machine, so none of your data is ever shared with us. The emails are sent by you from your own gmail account and automatically organized into a label in your inbox called ‘PrivacyBot’.  If you are interested in reading a step-by-step guide on how this is accomplished, you can access our public Github repository once it is released.</p>
                      &nbsp;
                      <p className="text-sm text-gray-400"><i>Note: As of now, this only works with gmail users.</i></p>
                      &nbsp;
                      <h4 className="text-xl font-medium  mb-2">3) Any replies/next steps are sent to your inbox.</h4>
                      &nbsp;
                      <p className="text-lg text-gray-400">Any follow-ups from the companies themselves go directly back to the data subject, aka: you. All further communications will be between you and the company, we’re just helping to kick start the process and it’s up to you how you want to handle the following email correspondence with the data brokers. You can see all of the sent emails in your “sent” folder. We have curated a list of potential follow-ups you might receive and suggested sample responses for you, which you will receive in an email following your use of PrivacyBot. Note that the number of reply emails can be quite substantial, but they will be staggered over several days (we tested ourselves, we know.)</p>
                    </li>
                  </ul>
                </div>

              </main>

              {/* Nav sidebar */}
              <aside className="md:w-64 mb-16 md:mb-0 md:mr-10 md:flex-shrink-0" data-aos="fade-up" data-aos-delay="200">
                <h4 className="text-lg font-medium px-3 pb-3 border-b border-gray-800">Frequently Asked Questions</h4>
                <nav>
                  <ul>
                  <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 1 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(1); }}
                      >
                        <span>How does PrivacyBot work?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                  <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 3 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(3); }}
                      >
                        <span>What are data brokers and people search sites?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 2 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(2); }}
                      >
                        <span>Why should I care about who has my data?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 4 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(4); }}
                      >
                        <span>Which data brokers / people search sites / marketing list companies does PrivacyBot support?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page ===5 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(5); }}
                      >
                        <span>What is the California Consumer Privacy Act (CCPA)?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 6 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(6); }}
                      >
                        <span>Does PrivacyBot work if I am not a California resident?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 7 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(7); }}
                      >
                        <span>What PII do I need to provide? </span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 8 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(8); }}
                      >
                        <span>The email was sent to the data broker but they didn’t respond, what should I do?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 9 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(9); }}
                      >
                        <span>A company is intentionally non compliant with my request, what should I do?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 10 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(10); }}
                      >
                        <span>Can I trust PrivacyBot?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 11 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(11); }}
                      >
                        <span>I ran into an error, how do I fix it?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 12 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(12); }}
                      >
                        <span>One (or several) of the data broker privacy department contact emails bounced / your databroker service list is out of date. Can I fix it?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 13 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(13); }}
                      >
                        <span>I am not a gmail user, can I still use PrivacyBot?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 14 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(14); }}
                      >
                        <span>Why does PrivacyBot ask for the permissions to ‘send emails’ and ‘view and modify but not delete’ in my gmail?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 15 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(15); }}
                      >
                        <span>I am a [data broker / marketing list company / people search site] who received a request. What do I do?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 16 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(16); }}
                      >
                        <span>My company does not deal with consumer data and I think it should be removed from the PrivacyBot services list</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 17 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(17); }}
                      >
                        <span>Is PrivacyBot an ‘authorized agent’ ?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                    <li className="py-2 border-b border-gray-800">
                      <a
                        className={`flex items-center px-3 group text-gray-400 hover:text-purple-600 transition duration-150 ease-in-out ${page === 18 && 'text-purple-600'}`}
                        href="#0"
                        onClick={(e) => { e.preventDefault(); setPage(18); }}
                      >
                        <span>Can I contribute to this project?</span>
                        <svg className="w-3 h-3 fill-current flex-shrink-0 ml-2 opacity-0 group-hover:opacity-100 group-hover:text-purple-600 group-hover:translate-x-1 transition duration-150 ease-in-out transform" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </nav>
              </aside>

            </div>
          </div>
        </div>

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default FAQ;






// import React from 'react';

// import Header from '../partials/Header';
// import PageIllustration from '../partials/PageIllustration';
// import PricingTables from '../partials/PricingTables';
// import Faqs from '../partials/Faqs';
// import Testimonials from '../partials/Testimonials';
// import Cta from '../partials/Cta';
// import Footer from '../partials/Footer';

// function FAQ() {
//   return (
//     <div className="flex flex-col min-h-screen overflow-hidden">

//       {/*  Site header */}
//       <Header />

//       {/*  Page content */}
//       <main className="flex-grow">

//         {/*  Page illustration */}
//         <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
//           <PageIllustration />
//         </div>

//         {/*  Page sections */}
//         {/* <PricingTables /> */}
//         <Faqs />
//         {/* <Testimonials /> */}
//         {/* <Cta /> */}

//       </main>

//       {/*  Site footer */}
//       <Footer />

//     </div>
//   );
// }

// export default FAQ;