import React from 'react';

import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import CtaContact from '../partials/CtaContact';
import Footer from '../partials/Footer';

function Contact() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">

        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            <div className="pt-32 pb-12 md:pt-40 md:pb-20">

              {/* Page header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 mb-4">Privacy Policy</h1>
                <p className="text-xl text-gray-400">Last updated: April 16, 2021.</p>
                &nbsp;
                <p className="text-xl text-gray-400">This statement describes PrivacyBot’s policies and procedures regarding the collection and transfer (or absence thereof) of your information when you use the tool.</p>
              </div>

              {/* Page content */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-26">
                <h1 className="h3 mb-4">What information do we collect and how? </h1>
                <p className="text-xl text-gray-400">We do NOT use any tracking technologies or cookies. Our tool is run entirely locally so we never have access to your data. We are simply an email routing tool that you run entirely on your own device.</p>
                &nbsp;
                <p className="text-xl text-gray-400">As part of our tool, you are required to provide certain personally identifiable information (PII) to initiate the data deletion request process under California Consumer Privacy Act (CCPA). Our tool transfers this data directly to data brokers so that they can confirm your identity before proceeding with the data deletion request. At no point is your information stored on any of our servers or available to us.</p>
                &nbsp;
                <p className="text-xl text-gray-400">The only instance in which we may store your data is if you email us directly, in which case your email will be stored so long as the message is in our inbox, for communication and debugging purposes.</p>
              </div>

              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-26">
                <h1 className="h3 mb-4">How is my information used?</h1>
                <p className="text-xl text-gray-400">We do NOT retain any of your personal information such as your name, email address, phone number, etc. Any of the information you provide is transferred directly to data brokers to verify your identity.</p>
              </div>

              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-26">
                <h1 className="h3 mb-4">Is my personal information stored?</h1>
                <p className="text-xl text-gray-400">We do NOT store any of your information. We do not even have access to it. Using our tool, you will initiate the email requests directly from your local machine and you will handle the following correspondence with the data brokers.</p>
              </div>

              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-26">
                <h1 className="h3 mb-4">Do we use ‘cookies’?</h1>
                <p className="text-xl text-gray-400">We do not use cookies for tracking purposes. You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Note that If you turn cookies off, some of the features that make your site experience more efficient may not function properly.</p>
              </div>

              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-26">
                <h1 className="h3 mb-4">How do I opt out of data collection? </h1>
                <p className="text-xl text-gray-400">There is no need to opt out, we do not collect any data to begin with. For more details, see below</p>
                
              </div>

              {/* CCPA header */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 mb-4">CCPA Policy</h1>
              </div>

              {/* CCPA Content */}
              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-26">
                <h1 className="h3 mb-4">Right to know</h1>
                <p className="text-xl text-gray-400">We do not collect, possess, or sell any data of yours for you to know, so this CCPA clause does not apply to our tool in this case.</p>
              </div>

              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-26">
                <h1 className="h3 mb-4">Right to delete</h1>
                <p className="text-xl text-gray-400">We do not collect, possess, or sell any data of yours for you to delete, so this CCPA clause does not apply to our tool in this case.</p>
              </div>

              <div className="max-w-3xl mx-auto text-center pb-12 md:pb-26">
                <h1 className="h3 mb-4">Right to opt out of sale of personal info to third parties</h1>
                <p className="text-xl text-gray-400">We do not collect, possess, or sell any data of yours for you to opt out of sale, nor do we have any relationships with any third party companies, so this CCPA clause does not apply to our tool in this case.</p>
                
              </div>

              {/* Contact header */}
              {/* <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                <h1 className="h1 mb-4">Contact Us</h1>
                <p className="text-xl text-gray-400">If you have any questions about this Privacy Policy, you can contact us at privacybot.berkeley@gmail.com.</p>
              </div> */}

              {/* Contact form
              <form className="max-w-xl mx-auto">
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="first-name">First Name <span className="text-red-600">*</span></label>
                    <input id="first-name" type="text" className="form-input w-full text-gray-300 border-red-500 focus:border-red-500" placeholder="Enter your first name" required />
                    <p className="text-red-500 text-sm mt-2">This field is required</p>
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="last-name">Last Name <span className="text-red-600">*</span></label>
                    <input id="last-name" type="text" className="form-input w-full text-gray-300" placeholder="Enter your last name" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                    <input id="email" type="email" className="form-input w-full text-gray-300" placeholder="Enter your email address" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="subject">Subject <span className="text-red-600">*</span></label>
                    <input id="subject" type="text" className="form-input w-full text-gray-300" placeholder="How can we help you?" required />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="country">Country</label>
                    <select id="country" className="form-select w-full text-gray-300">
                      <option>United States</option>
                      <option>United Kingdom</option>
                      <option>Germany</option>
                    </select>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="message">Message</label>
                    <textarea id="message" rows="4" className="form-textarea w-full text-gray-300" placeholder="Write your message"></textarea>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-4">
                  <div className="w-full px-3">
                    <label className="flex items-center">
                      <input type="checkbox" className="form-checkbox" />
                      <span className="text-gray-400 ml-2">I agree to the privacy policy</span>
                    </label>
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mt-6">
                  <div className="w-full px-3">
                    <button className="btn text-white bg-purple-600 hover:bg-purple-700 w-full">Send</button>
                  </div>
                </div>
              </form> */}

            </div>
          </div>
        </section>

        {/*  Bottom CTA */}
        {/* <CtaContact /> */}

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Contact;